<template>
  <div>
    <FormulateForm v-model="values" :schema="schema" @submit="submitHandler" />
    <br />
    <textarea id="json" v-model="jsonString" @focus="jsonfocus=true" @blur="jsonfocus=false"></textarea>
  </div>
</template>

<script>
import Vue from 'vue'
import VueFormulate from '@braid/vue-formulate'
import MyFormulateAutocomplete from './MyFormulateAutocomplete.vue'
import axios from 'axios';

Vue.component('MyFormulateAutocomplete', MyFormulateAutocomplete)

Vue.use(VueFormulate, {
  library: {
    autocomplete: {
      classification: 'text',
      component: 'MyFormulateAutocomplete'
    }
  }
})

export default {
  name: "Form",
  watch: {
    values: {
      deep: true,
      handler: function(newValue) {
        localStorage.setItem('applicationData', JSON.stringify(newValue));
        this.jsonString = JSON.stringify(newValue, null, 2);
      }
    },
    jsonString: function(newValue) {
      if(this.jsonfocus)
        this.values = JSON.parse(newValue);
    }
  },
  mounted() {
    const applicationData = localStorage.getItem('applicationData');
    this.values = JSON.parse(applicationData ? applicationData : {});
    this.jsonString = JSON.stringify(this.values);
  },
  methods: {
    async submitHandler(data) {
      this.schema[this.schema.length-1].label = 'Sendet ...';
      this.schema[this.schema.length-1].disabled = true;
      let schema = this.schema;
      axios.post('/apply.php', data)
        .then(function() {
          schema[schema.length-1].label = 'Gesendet!';
        })
        .catch(function() {
          schema[schema.length-1].label = 'Es ist ein Fehler aufgetreten!';
          schema[schema.length-1].disabled = false;
        });
    }
  },
  data() {
    return {
      jsonfocus: false,
      jsonString: '',
      values: {},
      schema: [
        {
          component: "h3",
          children: "NDS Förderung 2022 Router",
        },
        {
          component: "p",
          children: "Mit diesem Formular können Anträge für die Freifunkförderung 2022 des Landes Niedersachsen vorbereitet werden. Nach Absenden des Formulars erhältst du deinen Antrag als PDF per E-Mail. Um deinen Antrag abzuschließen, unterzeichne das PDF und sende es an foerderung@freifunknds.de."
        },
        {
          component: "p",
          children: "Deine Formulareingaben werden auf deinem Computer automatisch zwischengespeichert. Möchtest du deine Formulareingaben an einen anderen Computer übertragen, nutze bitte das Textfeld am Ende des Formulars. Es enthält alle Eingaben des Formulars in maschinenlesbarer Form. Nach Absenden des Formulars erhältst du eine Kopie dieser Daten per E-Mail."
        },
        {
          component: "p",
          children: "Antragsfrist ist der XX.XX.2022."
        },
        {
          component: "p",
          children: "Die übermittelten Daten werden für die Antragsbearbeitung an die koordinierende Stelle weitergeleitet."
        },
        {
          label: "Freifunk-Community",
          name: "community",
          type: "autocomplete",
          options: [
            "Braunschweig",
            "Göttingen",
            "Hannover",
            "Lüneburg",
            "Nordheide",
            "Nordwest",
            "Springe",
            "Uelzen",
          ],
          validation: "required",
        },
        {
          label: "Name des Antragstellers",
          name: "name",
          validation: "required",
        },
        {
          label: "2. Adresszeile (Appartment-Nr. / care of / ...)",
          name: "2line",
          validation: "optional",
        },
        {
          label: "Strasse",
          name: "street",
          validation: "required",
        },
        {
          label: "Hausnummer",
          name: "housenr",
          validation: "required",
        },
        {
          label: "Postleitzahl",
          name: "zipcode",
          validation: "required",
        },
        {
          label: "Ort",
          name: "city",
          validation: "required",
        },
        {
          label: "E-Mail",
          name: "email",
          validation: "required|email",
        },
        {
          label: "Installationen",
          name: "locations",
          type: "group",
          validation: "min:1,length",
          repeatable: true,
          "add-label": "+ Installation",
          value: [],
          children: [
            {
              label: "Straße",
              name: "street",
              validation: "required",
            },
            {
              label: "Hausnummer",
              name: "housenr",
              validation: "required",
            },
            {
              label: "PLZ",
              name: "zipcode",
              validation: "required",
            },
            {
              label: "Ort",
              name: "city",
              validation: "required",
            },
            {
              label: "Hardware",
              name: "hardware",
              type: "group",
              validation: "min:1,length",
              repeatable: true,
              "add-label": "+ Hardware",
              value: [],
              children: [
                {
                  label: "Model",
                  name: "model",
                  type: "autocomplete",
                  options: [
                    "Ubiquiti AC-Pro",
                    "Ubiquiti AC-Mesh",
                    "Ubiquiti AC-Mesh + UMA-D",
                    "Ubiquiti ER-X-SFP",
                    "Ubiquiti EP-S16",
                    "Ubiquiti AF-5XHD mit AF‑5G30‑S45 und ISO-BEAM-620",
                    "AVM Fritz!Box 4040",
                  ],
                  validation: "required",
                },
                {
                  label: "Anzahl",
                  name: "amount",
                  validation: "required|min:1,number",
                },
              ],
            },
          ],
        },
        {
          label: "Absenden",
          type: "submit",
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  textarea {
    width: 100%;
    resize: vertical;
  }

  #json {
    height: 600px;
  }
</style>
