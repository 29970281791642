<template>
  <div id="app">
    <img alt="Freifunk Niedersachsen" src="./assets/logo.png">
    <Form />
    <p>
      <a href="https://www.bznb.de/kontakt/">Koordinierende Stelle - Breitbandzentrum Niedersachsen-Bremen</a><br />
      <a href="https://ffnw.de/impressum/">Technische Umsetzung - Freifunk Nordwest e.&nbsp;V.</a>
    </p>
  </div>
</template>

<script>
import Form from './components/Form.vue'

export default {
  name: 'App',
  components: {
    Form
  }
}
</script>

<style lang="scss">
@import '../node_modules/@braid/vue-formulate/themes/snow/snow.scss';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 20px auto;
  width: 400px;

  img {
    display: block;
    margin: 0 auto;
  }

  h3 {
    text-align: center;
  }

  .formulate-input-element {
    max-width: none;
  }

  .formulate-input-dropdown {
    position: absolute;
    z-index: 999;
    background-color: #fff;
    margin: -3px 0 0;
    padding: 0;
    list-style-type: none;
    border: 1px solid #cecece;
    border-top-color: #41b883;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    background: #fff;
  }

  .formulate-input-dropdown li {
    padding: 0.5em 1em;
    margin: 0;
  }

  .formulate-input-dropdown li:hover, .formulate-input-dropdown li[data-is-selected] {
    background-color: rgba(0,0,0,.05);
  }
}
</style>
